.opening {
    height: 100dvh;
    background-color: #FFFFFF;
    width: 100%;

    background: url('https://aloveyu.com/images/opening1.webp') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .container {
        z-index: 2;
        position: relative;
        // height: 100dvh;
        // justify-content: center;
        // align-items: center;
        padding: 25px;
        padding-bottom: 40px;
        padding-top: 30px;
        height: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }

    .box-guest{
        background-color: rgba(0, 0, 0, 0.75);
        max-width: 500px;
        margin: auto;
        border-radius: 8px;
        padding: 8px;
        font-family: Alata;
        font-size: 14px;

        p {
            color: #FFFFFF;
            margin: 0;
        }

        animation: fadeIn ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: both;
        animation-delay: 2s;
    }

    .title-name{
        font-family: Brittany;
        font-size: 55px;
        line-height: 110px;
        color: black;
        
        animation: fadeIn ease 3s;
        animation-iteration-count: 1;
        animation-fill-mode: both;
        animation-delay: 1s;
    }

    .date {
        // font-family: Arial Rounded MT Bold;
        font-size: 20px;
        font-weight: 600;
        font-family: Poppins;
        color: black;

        animation: fadeIn ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: both;
        animation-delay: 1.5s;
    }

    .opening-title {
        font-size: 20px;
        font-weight: 600;
        font-family: Poppins;
        color: black;

        animation: fadeIn ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: both;
        animation-delay: .5s;
    }

    .opening-tag {
        font-size: 18px;
        font-family: Alata;
        color: black;

        animation: fadeIn ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: both;
        animation-delay: 1.5s;
    }

    .nama-undangan {
        font-size: 20px;
        font-weight: bold;
        color: white;
    }

    .btn {
        font-family: Poppins;
        padding: 8px 10px;
        
        animation: fadeIn2 ease 2.5s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        animation-delay: 2.5s;

        img {
            filter: brightness(0) invert(1);
            margin-left: 8px;
            transform: translateY(-2px);
        }
    }
}