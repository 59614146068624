.content {
    height: 100%;
    background-color: #efeae7;

    .opening {
        background: url(https://aloveyu.com/images/opening-2.webp) center center / cover no-repeat;
        position: relative;
        text-align: center;

        .swipe-more {
            bottom: 90px;
            position: absolute;
            text-align: center;
            font-size: 14px;
            font-family: Poppins;
            color: white;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 14px;
            width: 80%;
            cursor: pointer;

            animation: fadeIn2 ease 3s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-delay: 1.5s;

            img {
                filter: brightness(0) invert(1);
                margin-left: 4px;
                transform: translateY(-2px);
            }
        }
    }

    .menghitung-hari{
        color: white;
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) , url(https://aloveyu.com/images/image-venue.webp) center center;
        background-size: cover;

        .title{
            font-family: Birthstone;
            font-size: 50px;
        }

        .date {
            font-size: 20px;
            font-family: Poppins;
        }

        .btn{
            font-family: Poppins;
        }
    }
}