.card-name {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px 16px;

    background: url(https://aloveyu.com/images/image-20.webp) center center;
    background-size: cover;
    position: relative;
    color: white;

    .and{
        font-family: Arial Rounded MT Bold;
        font-size: 40px;
        color: #000000;
    }

    img{
        border-radius: 10px;
    }

    .name {
        font-family: Coolvetica;
        font-size: 22px;
    }
}