.vip {
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) , url(https://aloveyu.com/images/image-background-greeting.webp) center center;
    background-size: cover;
    position: relative;
    font-family: Poppins;
    color: #ffffff;
    font-size: 14px;
    padding: 24px;
    text-align: left;
    height: 600px;
    align-content: center;
    display: grid;

    .title{
        font-size: 50px;
        font-family: Alata;
    }
}