.card-countdown {
    display: flex;
    justify-content: center;
    max-width: 560px;
    margin: auto;
    font-family: Poppins;
    font-size: 24px;

    .box-countdown {
        // background-color: black;
        color: white;
        border-radius: 10px;
        padding: 10px 6px;
        display: flex;
        flex-direction: column;
        width: 75px;
        font-weight: 600;

        p {
            margin: 0;
            // font-family: Arial Rounded MT Bold;
            font-size: 14px;
        }
    }
}