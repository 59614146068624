.card-place {
    border-radius: 12px;
    // box-shadow: 0px 3px 6px #00000029;
    padding: 16px;
    // background-color: #FFFFFF;
    max-width: 560px;
    margin: auto;
    // color: black;
    font-family: Poppins;

    animation: fadeIn ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-delay: .5s;

    .title {
        font-family: Madina;
    }

    .btn-aloveyu {
        &:hover {
            background-color: black;
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
        }
    }
}