.home{
    background: #FFFFFF;
    text-align: center;
    height: 100vh;
    position: relative;
    max-width: 560px;
    margin: auto auto 0;

    // .container {
    //     position: relative;
    //     height: 100dvh;
    // }

    .btn-music {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 50;
    }
}