.fly-button {
    position: sticky;
    position: -webkit-sticky;
    bottom: 20px;
    border-radius: 10px;
    // background-color: rgba(0, 0, 0, 0.9);
    background-color: white;
    color: white;
    margin: auto;
    width: 85%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    z-index: 50;

    animation: fadeIn ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-delay: 2s;

    .section {
        width: 25%;
        align-content: center;
        display: grid;
        justify-content: center;
        cursor: pointer;
    }

    .icon {
        height: 28px;
        width: 28px;
    }
}