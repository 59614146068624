.quote {
    font-family: Poppins;
    font-size: 14px;
    padding: 24px;

    &.greeting {
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) , url(https://aloveyu.com/images/image-background-greeting.webp) center center;
        background-size: cover;
        position: relative;
        color: white;
        height: 700px;
        align-content: center;
        display: grid;
    }

    &.closing {
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) , url(https://aloveyu.com/images/image-background-closing.webp) center center;
        background-size: cover;
        position: relative;
        color: white;
        height: 700px;
        align-content: center;
        display: grid;
    }

    &.quote1 {
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) , url(https://aloveyu.com/images/quote1.webp) center center;
        background-size: cover;
        position: relative;
        color: white;
        height: 700px;
        align-content: center;
        display: grid;
    }

    &.quote2 {
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) , url(https://aloveyu.com/images/quote2.webp) center center;
        background-size: cover;
        position: relative;
        color: white;
        height: 700px;
        align-content: center;
        display: grid;
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .name {
        margin-top: 20px;
        font-family: Birthstone;
        font-size: 50px;
    }

    .parent {
        font-weight: 600;
        font-size: 16px;
    }

    .hashtag {
        font-family: Alata;
        font-size: 18px;
    }
}